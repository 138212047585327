// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

table {
  width: 100%;
  margin-top: 20px;
}

th, td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f4f4f4;
}

a {
  color: blue;
  text-decoration: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 8px 16px; /* 调整内边距以适应内容和保持按钮看起来不那么拥挤 */
  margin: 0 5px; /* 左右各添加10px的间距 */
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* 轻微的阴影效果增加立体感 */
  text-align: center;
  line-height: normal;
}

.custom-file-upload img {
  height: 20px; /* 设置图标的统一高度 */
  width: 20px; /* 设置图标的统一宽度 */
}

.custom-file-upload:hover {
  background-color: #e0e0e0; /* 悬停时的背景色 */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* 添加阴影 */
}

@media (max-width: 600px) {
  .custom-file-upload {
      padding: 10px; /* 在小屏幕上增大按钮的点击区域 */
  }
}




`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB,EAAE,4BAA4B;EAC/C,aAAa,EAAE,iBAAiB;EAChC,eAAe;EACf,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,qCAAqC,EAAE,iBAAiB;EACxD,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY,EAAE,cAAc;EAC5B,WAAW,EAAE,cAAc;AAC7B;;AAEA;EACE,yBAAyB,EAAE,YAAY;EACvC,qCAAqC,EAAE,SAAS;AAClD;;AAEA;EACE;MACI,aAAa,EAAE,mBAAmB;EACtC;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\ntable {\n  width: 100%;\n  margin-top: 20px;\n}\n\nth, td {\n  text-align: left;\n  padding: 8px;\n}\n\nth {\n  background-color: #f4f4f4;\n}\n\na {\n  color: blue;\n  text-decoration: none;\n}\n\n.custom-file-upload {\n  display: inline-block;\n  padding: 8px 16px; /* 调整内边距以适应内容和保持按钮看起来不那么拥挤 */\n  margin: 0 5px; /* 左右各添加10px的间距 */\n  cursor: pointer;\n  background-color: #f0f0f0;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* 轻微的阴影效果增加立体感 */\n  text-align: center;\n  line-height: normal;\n}\n\n.custom-file-upload img {\n  height: 20px; /* 设置图标的统一高度 */\n  width: 20px; /* 设置图标的统一宽度 */\n}\n\n.custom-file-upload:hover {\n  background-color: #e0e0e0; /* 悬停时的背景色 */\n  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* 添加阴影 */\n}\n\n@media (max-width: 600px) {\n  .custom-file-upload {\n      padding: 10px; /* 在小屏幕上增大按钮的点击区域 */\n  }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
